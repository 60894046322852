import React from 'react'

export interface ITextInputProps {
  id: string
  name: string
  placeholder: string
  ariaLabel: string
  type?: 'text' | 'password'
  autoComplete?: string 
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export default function InputText({ id, name, placeholder, ariaLabel, type = 'text', autoComplete, value, onChange }: ITextInputProps) {
  return (
    <input 
      className='text-input'
      id={id} name={name}
      placeholder={placeholder}
      type={type} aria-label={ariaLabel}
      value={value} 
      autoComplete={autoComplete}
      onChange={onChange} />
  )
}
