export function dateStringToDisplayDate (dateString: string): string {
  return new Date(dateString).toLocaleString('sv-SE')
}

export function dateStringToSessionDisplayDate (dateString: string): string {
  const date = new Date(dateString)
  if (date.getUTCFullYear() == new Date().getUTCFullYear()) {
    return date.toLocaleString('sv-SE', { day: "numeric", month: 'short', hour: "2-digit", minute: "2-digit", second: "2-digit" })
  }
  else {
    return date.toLocaleString('sv-SE', { day: "numeric", month: 'short', year: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit" })
  }
}

export function dateStringToRestockDisplayDate (dateString: string): string {
  const date = new Date(dateString)
  return date.toLocaleString('sv-SE', { day: "numeric", month: 'short' })
}

export function differenceInMinutes(date1str: string, date2str: string): number {
  const date1: Date = new Date(date1str)
  const date2: Date = new Date(date2str)
  return Math.floor(Math.abs(date1.getTime() - date2.getTime()) / 60000) // 60000 = milliseconds to minutes factor
}
