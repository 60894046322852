import React from 'react'
import './CustomerBannedInfo.scss'

export interface ICustomerBannedInfoProps {
  bannedAt?: string
}

export default function CustomerBannedInfo({ bannedAt } : ICustomerBannedInfoProps) {
  return (      
    <div className='justify-self-center self-center label__banned mr-3'>
      <div className='customer-banned__title-text'>Spärrad</div>
      {bannedAt &&<div className='customer-banned__banned-at-text text-neutral-600'>{bannedAt}</div>}
    </div>
  )
}
