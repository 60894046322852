import React from "react"

export interface SelectOption {
    displayText: string
    value: string
}

export interface InputSelectProps {
  options: Array<SelectOption>
  defaultFilterOption?: SelectOption
  onInputSelectChange: (selected: SelectOption) => void
}

const InputSelect = ({ options, defaultFilterOption, onInputSelectChange }: InputSelectProps) => {
  const defaultValue = defaultFilterOption ?? options[0]

  const onDropDownChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value
    const newSelectedOption = options.find((o) => o.value === value)
    if(newSelectedOption !== undefined) {
      onInputSelectChange(newSelectedOption)
    } else {
      throw new Error("InputSelect: Selected value not found in given options array")
    }
  }

  return (
    <div className="grid justify-items-start content-center px-3">
      <label htmlFor='inputSelect'>Filter</label>
      <select
        id="inputSelect"
        name="inputSelect"
        onChange={onDropDownChange}
        className="select-filter"
        defaultValue={defaultValue.value}
      >
        {options.map((option) =>
          <option key={option.value} value={option.value}>
            {option.displayText}
          </option>
        )}
      </select>
    </div>

  )
}

export default InputSelect