import React from 'react'
import Feed from '../components/Feed/Feed'

export default function LiveFeed() {
  return (
    <div className='pt-5'>
      <Feed />
    </div>
  )
}
