import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import config from '../../config'
import { StoreContext } from '../../contexts/store-context'
import { SessionBrief } from '../../types/SessionBrief'
import { get, post } from '../../utils/http'
import { dateStringToSessionDisplayDate, differenceInMinutes } from '../../utils/dateUtils'
import displayPrice from '../../utils/displayPrice'
import Modal from '../Modal/Modal'
import SessionInfo from '../SessionInfo/SessionInfo'
import { useNavigate  } from "react-router-dom"
import CloseIcon from '../Icons/CloseIcon'
import SpinnerIcon from '../Icons/SpinnerIcon'
import NavigateBack from '../NavigateBack/NavigateBack'
import UserIcon from '../Icons/UserIcon'
import '../../styles/shared/list.scss'
import './CustomerInfo.scss'
import sessionStateStyle from '../../utils/sessionStateStyle'
import InputCheckbox from '../InputCheckbox/InputCheckbox'
import CustomerBannedInfo from '../CustomerBannedInfo/CustomerBannedInfo'
import SessionAccessDeniedInfo from '../SessionAccessDeniedInfo/SessionAccessDeniedInfo'
import AcrFlag from '../AcrFlag/AcrFlag'

interface Customer {
  banned: boolean
  bannedAt: string
  id: string
  sessions: Array<SessionBrief>
}

interface DoorBlock {  
  customerId: string
  createdById: string
  createdByEmail: string
  createdAt: string
}

export default function CustomerInfo() {
  const [customer, setCustomer] = useState<Customer | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const { id, acr, sessionId } = useParams()
  const { store } = StoreContext.useContainer()
  const [banCheck, setBanCheck] = useState(false)
  const [banError, setBanError] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const backUrl = useRef(location?.state?.previousUrl ?? null)

  const paymentType = {
    Swish: "SWISH",
    Card: "KORT"
  }
  const fetchCustomerInfo = async () => {
    setLoading(true)
    setCustomer(null)
    setError(false)
    try {
      const result = await get<Customer>(`${config.STORE_API_BASE_URL}/v2/monitor/customer/${store.selectedStore?.id}/${id}/${acr}`)
      setCustomer(result)
      setBanCheck(result.banned)
    } catch (error) {
      setError(true)
    }
    setLoading(false)
  }

  const onModalClose = () => {
    navigate(`/customers/${id}/${acr}`)
  }

  const numberOfSuspiciousSessions = (sessions: Array<SessionBrief>) => {
    return sessions.reduce((prev, curr) => {
      return curr.suspicious ? prev + 1 : prev
    }, 0)
  }

  const onCheckChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    toggleBanCustomer(!e.target.checked)
  }

  const toggleBanCustomer = async (checked: boolean) => {
    if(checked) {
      setBanError(false)
      try {
        const response: DoorBlock = await post(`${config.STORE_API_BASE_URL}/v2/monitor/customer/${id}/${acr}/ban`, null)
        if(customer !== null) {
          const newCustomer = customer
          newCustomer.banned = response !== null
          newCustomer.bannedAt = response.createdAt
          setCustomer(newCustomer)
        }
        setBanCheck(true)
      } catch (error) {
        setBanCheck(false)
        setBanError(true)
      }
    } else {
      try {
        await post(`${config.STORE_API_BASE_URL}/v2/monitor/customer/${id}/${acr}/unban`,  null)
        setBanCheck(false)
      } catch (error) {
        setBanCheck(true)
        setBanError(true)
      }
    }
  }

  useEffect(() => {
    if (store.selectedStore?.id) {
      fetchCustomerInfo()
    }
  }, [store.selectedStore?.id])

  /** TODO
  Kunder: Filter: Alla, spärrade
  Kunder: Grön = bra kund (inga misstänkta)
  Kunder: Gul = Kund med misstänkta
  Kunder: Röd ? spärrad kund

  Liveflöde - tiden som visas ska vara första tiden för första eventet i varje column

  */
  return (
    <div className='customer-info'>
      {/* TODO lägg till filter när BE implementera det */}
      {/* <Filter onFilter={fetchCustomerInfo} /> */}

      <NavigateBack backUrl={backUrl.current} />
      {customer && 
      <div className='my-4'>
        <div className={`customer-info__header${banCheck ? ' customer-info__header--banned' : ''}`}>
          <div className='icon-text icon-large hidden sm:grid content-center p-3'><UserIcon /></div>
          <div className='grid grid-flow-row auto-rows-min'>
            <div className='grid grid-flow-col auto-cols-min px-4 pt-6'>
              <div className='icon-text grid sm:hidden content-center pr-3 sm:p-3'><UserIcon /></div>
              <div className='self-center flex items-center'>#{customer.id}</div>
            </div>
            <div className='px-4 sm:pb-6'>
              Antal kundvarv {customer.sessions.length} varav misstänkta kundvarv {numberOfSuspiciousSessions(customer.sessions)}
            </div>
          </div>
          <div className='grid grid-flow-col auto-cols-max place-content-center p-3 customer-info__checkbox'>
            {banCheck && <CustomerBannedInfo bannedAt={customer?.bannedAt} />}
            <div className='grid place-content-center'>
              <InputCheckbox id={'ban-checkbox'} checked={!banCheck} onCheckChanged={onCheckChanged} />
            </div>
          </div>
        </div>
        {banError && <p className='error px-3 text-right'>Det gick inte att spärra kunden</p>} 
      </div>
      }
      <div className='list'>
        {loading &&
        <div className='grid place-content-center'>
          <SpinnerIcon />
        </div>
        }
        {customer && customer.sessions.map((session) => 
          <div className={`list-item  ${sessionStateStyle(session)}`} key={session.id}>
            <Link to={`/customers/${id}/${acr}/${session.id}`} className='list-item__left-cell'>
              <div className='list-item__order'>
                <div className='flex items-center'>#{session.id}{session.acr && <div className='ml-3'><AcrFlag acr={session.acr} width={18}/></div>}</div>
                <div>{dateStringToSessionDisplayDate(session.firstActivity)} - {dateStringToSessionDisplayDate(session.latestActivity)} ({differenceInMinutes(session.firstActivity, session.latestActivity)} min)</div>
              </div>
              <div className='list-item__purchase'>{session.itemCount} produkter{session.paymentType ? ', ' + paymentType[session.paymentType as keyof typeof paymentType] : ''}</div>
              <div className='list-item__right flex'>
                {session.accessDeniedReason && <div className='flex pb-4 md:pb-0'><SessionAccessDeniedInfo accessDeniedReason={session.accessDeniedReason } /></div>}
                <div className='list-item__price'>{displayPrice(session.totalPrice)} kr</div>
              </div>
            </Link>
          </div>
        )}
        {!loading && !error && customer && customer.sessions.length === 0 && <div className='grid place-items-center'>Ingen kundhistorik kunde hittas för angiven butik och tidsintervall.</div>}
        {!loading && error && <p className='error text-center'>Någonting gick fel. Försök igen genom att ladda om sidan.</p>}
      </div>
      {sessionId && 
      <div>
        <Modal show={!!sessionId} onClose={onModalClose}>
          <div className='grid grid-cols-2 gap-4'>
            <div className='justify-self-start'>
              <Link to={`/customers/${id}/${acr}`} className='icon-text'><CloseIcon />Stäng</Link>
            </div>
          </div>
          <SessionInfo />
        </Modal>
      </div>
      }
    </div>
  )
}
