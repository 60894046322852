import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr'
import { useState, useEffect } from 'react'
import { UserContext } from '../contexts/user-context'
import { StoreContext } from '../contexts/store-context'
import { get } from '../utils/http'
import { WebSocketInvocation } from './webSocketInvocation'

let connection: HubConnection

export default function useSignalRFeed<TFeedObject>(
  signalRUrl: string,
  initialDataBaseUrl: string,
  invocations: Array<WebSocketInvocation<TFeedObject>>) {
  const { store } = StoreContext.useContainer()
  const { user } = UserContext.useContainer()
  const [initialFeed, setInitialFeed] = useState<Array<TFeedObject>>([])
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)

  const onBeforeUnload = () => {
    if (connection) {
      connection.stop() //on unmount close signalr connection
    }
  }

  const setupSignalR = async () => {
    connection = new HubConnectionBuilder()
      .withUrl(signalRUrl, {
        accessTokenFactory: () => user.token ?? "",
        withCredentials: false,
      })
      .withAutomaticReconnect()
      .build()

    invocations.forEach(invocation => {
      connection.on(invocation.name, invocation.action)
    })

    connection.onreconnecting(() => {
      setLoading(true)
    })

    connection.onreconnected(async () => {
      await connection.invoke("JoinStore", store.selectedStore?.id)
    })

    try {
      await connection.start()
        .then(async () => {
          await connection.invoke("JoinStore", store.selectedStore?.id)
          setLoading(false)
          setError(false)
        })
    } catch (error) {
      setError(true)
      setLoading(false)
    }
  }

  const fetchInitialFeedData = async () => {
    const data = await get<Array<TFeedObject>>(`${initialDataBaseUrl}/${store.selectedStore?.id}`)
    setInitialFeed(data)
    setLoading(false)
  }

  useEffect(() => {
    async function setup() {
      if (store.selectedStore?.id) {
        await fetchInitialFeedData()
        setupSignalR()
      }
    }
    setup()
  }, [store.selectedStore?.id])

  useEffect(() => {
    return () => {
      if (connection) {
        connection.stop() //on unmount close signalr connection
        window.removeEventListener('pagehide', onBeforeUnload, false)
      }
    }
  }, [])

  window.addEventListener("pagehide", onBeforeUnload, false)

  return { initialFeed, loading, error }
}
