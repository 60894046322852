import { User } from "../contexts/user-context"

export interface TokenPayload {
  email: string
  role: string | Array<string>
}

export const getUserFromToken = (token: string | null) => {
  if (token) {
    const tokenPayloadBase64 = token.split('.')[1]
    const tokenPayload = JSON.parse(atob(tokenPayloadBase64)) as TokenPayload

    let roles
    if (Array.isArray(tokenPayload.role)) {
      roles = tokenPayload.role
    }
    else {
      roles = [tokenPayload.role]
    }

    return {
      displayName: tokenPayload.email,
      token,
      roles,
    } as User
  } else {
    return null
  }
}