import React, { ForwardedRef, PropsWithChildren, forwardRef, useImperativeHandle, useRef, useState } from 'react'
import './BulletMenu.scss'

interface BulletMenu {
  hideMenu: () => void
}

const BulletMenu = forwardRef((props: PropsWithChildren, ref: ForwardedRef<BulletMenu | undefined>) => {
  const [showMenu, setShowMenu] = useState(false)
  const menuButtonRef = useRef<HTMLButtonElement | null>(null)
  const dropdownRef = useRef<HTMLDivElement | null>(null)

  useImperativeHandle(ref, () => ({
    hideMenu() {
      setShowMenu(false)
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }))

  const displayMenu = () => {
    document.addEventListener("mousedown", handleClickOutside)
    setShowMenu(!showMenu)
  }

  function handleClickOutside(event: MouseEvent) {
    if (menuButtonRef.current && !menuButtonRef.current.contains(event.target as HTMLButtonElement)
    && dropdownRef.current && !dropdownRef.current.contains(event.target as HTMLDivElement)) {
      setShowMenu(false)
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }

  return (
    <div className='relative'>
      <button onClick={displayMenu} ref={menuButtonRef} className='button button-icon button-white button-bullet'>&bull;&bull;&bull;</button>
      {showMenu &&
        <div className='dropdown' ref={dropdownRef}>
          {props.children}
        </div>}
    </div>
  )
})

BulletMenu.displayName = 'BulletMenu'
export default BulletMenu
