import { useState } from "react"
import { createContainer } from "unstated-next"
import { STORE_ID_KEY } from "../constants/localStorageKeys"

//https://github.com/jamiebuilds/unstated-next

export interface Store {
 id: string,
 name: string
}

export interface StoreContext {
  selectedStore: Store | null,
  availableStores: Array<Store>
}

export const defaultState: StoreContext = {
  selectedStore: null,
  availableStores: []
}

export function useStore(initialState = defaultState) {
  const [store, setStoreContext] = useState<StoreContext>(initialState)
  const setStore = (context: StoreContext) => {
    const selectedStore = context.selectedStore?.id
    if(selectedStore) {
      localStorage.setItem(STORE_ID_KEY, selectedStore)
    }

    setStoreContext(context)
  }

  return { store, setStore }
}

export const StoreContext = createContainer(useStore)