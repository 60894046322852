import React from 'react'
import { UserContext, defaultUser } from '../contexts/user-context'
import { useNavigate } from "react-router-dom"
import { AUTH_KEY, AUTH_TYPE_KEY } from '../constants/localStorageKeys'

const Logout = () => {
  const navigate = useNavigate()
  const userContext = UserContext.useContainer()
  const logoutUser = () => {
    localStorage.removeItem(AUTH_KEY)
    localStorage.removeItem(AUTH_TYPE_KEY)
    userContext.setUser(defaultUser)
    navigate('/login')
  }

  return (<button type="button" className='button' onClick={logoutUser}>Logga ut</button>)
}

export default Logout