import React from "react"
import { StoreContext } from "../contexts/store-context"

const SelectStore = () => {
  const { store, setStore } = StoreContext.useContainer()

  const onChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value
    const newSelectedStore = store?.availableStores.find((store) => store.id === value) ?? null
    if (store && newSelectedStore) {
      setStore({ ...store, selectedStore: newSelectedStore })
    }
  }

  return (
    <>
      {store && store.selectedStore &&
        <select onChange={onChange} defaultValue={store.selectedStore.id} className="select-header">
          {store.availableStores.map((store) =>
            <option key={store.id} value={store.id}>
              {store.name}
            </option>
          )}
        </select>
      }
    </>
  )
}

export default SelectStore