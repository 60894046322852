import  React, { useEffect, useState } from 'react'
import InputDate from '../InputDate/InputDate'
import './InputDateRange.scss'

export interface InputDateRangePayLoad {
 from: Date
 to: Date
}

export interface InputDateRangeProps {
  onDateRangeChange: (filter: InputDateRangePayLoad) => void
  defaultDateRange?: InputDateRangePayLoad | null
}

export default function InputDateRange ({ onDateRangeChange, defaultDateRange = null }: InputDateRangeProps) {
  const defaultFromDate = defaultDateRange ? new Date(defaultDateRange.from) : new Date()
  const defaultToDate = defaultDateRange ? new Date(defaultDateRange.to) : new Date()
  const [fromDate, setFromDate] = useState(defaultFromDate)
  const [toDate, setToDate] = useState(defaultToDate)
  const [validationError, setValidationError] = useState(false)

  const setFromDateTime = (date: Date) => {
    return new Date(new Date(date).setHours(0,0,0,0))
  }

  const setToDateTime = (date: Date) => {
    return new Date(new Date(date).setHours(23,59,59,59))
  }


  useEffect(() => {
    if(fromDate && toDate){
      const from = setFromDateTime(fromDate).getTime()
      const to = setToDateTime(toDate).getTime()
      if(from > to) {
        setValidationError(true)
      } else {
        setValidationError(false)

        onDateRangeChange({ from: fromDate, to: toDate })
      }}
  }, [fromDate, toDate])

  useEffect(() => {
    if(defaultDateRange && defaultDateRange.from !== fromDate && defaultDateRange.to !== toDate) {
      setFromDate(defaultFromDate)
      setToDate(defaultToDate)
    }

  }, [defaultDateRange])


  return (
    <div className={`input-range grid ${validationError ? '' : 'mb-4'}`}>
      <div className='grid grid-cols-2'>
        <div className='pr-3'>
          <label htmlFor='fromDate'>Startdatum</label>
          <InputDate onChange={setFromDate} value={fromDate} ariaLabel="fromDate" id='fromDate' name='fromDate' />
        </div>
        <div className='px-3'>
          <label htmlFor='toDate'>Slutdatum</label>
          <InputDate onChange={setToDate} value={toDate} ariaLabel="toDate" id='toDate' name='toDate' />
        </div>
      </div>
      {validationError &&
        <div className='pl-3 input-range__error'>
          <span className='error'>Startdatum kan inte vara större än startdatum.</span>
        </div>
      }
    </div>
  )
}
