import React from 'react'
import Overview from './pages/Overview'
import NotFound from './pages/NotFound'
import Login from './pages/Login'
import AuthenticatedPage from './components/AuthenticatedPage'
import { Routes, Route, Navigate } from "react-router-dom"
import { UserContext } from './contexts/user-context'
import LiveFeed from './pages/LiveFeed'
import CustomerTurnover from './pages/CustomerTurnover'
import Customer from './pages/Customer'
import Customers from './pages/Customers'
import Groceries from './pages/Groceries'

function App() {
  return (
    <div className="App">
      <UserContext.Provider>
        <Routes>
          <Route
            path="/overview"
            element={
              <AuthenticatedPage>
                <Overview />
              </AuthenticatedPage>
            }
          />
          <Route
            path="/live-feed"
            element={
              <AuthenticatedPage>
                <LiveFeed />
              </AuthenticatedPage>
            }
          />
          <Route
            path={`/sessions`}
            element={
              <AuthenticatedPage>
                <CustomerTurnover />
              </AuthenticatedPage>
            }
          />
          <Route
            path="/sessions/:sessionId"
            element={
              <AuthenticatedPage>
                <CustomerTurnover />
              </AuthenticatedPage>
            }
          />
          <Route
            path="/customers"
            element={
              <AuthenticatedPage>
                <Customers />
              </AuthenticatedPage>
            }
          />
          <Route
            path="/customers/:id/:acr"
            element={
              <AuthenticatedPage>
                <Customer />
              </AuthenticatedPage>
            }
          />
          <Route
            path="/customers/:id/:acr/:sessionId"
            element={
              <AuthenticatedPage>
                <Customer />
              </AuthenticatedPage>
            }
          />
          <Route
            path="/groceries"
            element={
              <AuthenticatedPage>
                <Groceries />
              </AuthenticatedPage>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Navigate replace to="/overview" />} />
          <Route
            path="*"
            element={
              <NotFound />
            }
          />
        </Routes>
      </UserContext.Provider>
      <div id='modal-portal'></div>
    </div>
  )
}

export default App
