import React, { useEffect, useMemo, useState } from 'react'
import ArrowIcon from '../Icons/ArrowIcon'
import './DatePicker.scss'
import { buildMonthWeeks, isToday } from './datePickerUtils'

export interface IDatePickerProps {
  value: Date,
  onChange: (date: Date) => void
}

export default function DatePicker({ value, onChange }: IDatePickerProps) {
  const displayMonths = ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December']
  const displayWeekDays = ['Må', 'Ti', 'On', 'To', 'Fr', 'Lö', 'Sö']
  const [localDate, setLocalDate] = useState(value)
  const weeks = useMemo(() => buildMonthWeeks(localDate), [localDate])

  const changeMonth = (direction: number) => {
    const date = new Date(localDate)
    const currentMonth = date.getMonth()
    date.setMonth(currentMonth + direction)
    setLocalDate(date)
  }

  const changeDay = (newDate: Date | null) => {
    if(newDate !== null) { 
      onChange(newDate)
    }
  }

  const dayClassNames =  (day: Date | null) => {
    return `${day !== null ? 'date-picker__day' : ''}${day !== null && isToday(day) ? ' date-picker__day-today' : ''}`
  }

  useEffect(() => {
    if(value) {
      setLocalDate(value)
    }
  }, [value])

  return (
    <div className='date-picker'>
      <div className='date-picker__months'>
        <button className='button button-secondary button-icon' onClick={() =>changeMonth(-1)}>
          <ArrowIcon direction='left' />
        </button>
        {displayMonths[localDate.getMonth()]} {localDate.getFullYear()}
        <button className='button button-secondary button-icon' onClick={() =>changeMonth(1)}>
          <ArrowIcon direction='right' />
        </button>
      </div>
      <div className='date-picker__days'>
        <table>
          <thead>
            <tr>
              {displayWeekDays.map((day, i) =>
                <th key={i}>
                  {day}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {weeks.map((weekDays, i) => 
              <tr key={i}>
                {weekDays.map((day, ix) => 
                  <td key={ix} onClick={() => changeDay(day)}>
                    <div className={dayClassNames(day)}>
                      {day? day.getDate() : ''}
                    </div>
                  </td> 
                )}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}
