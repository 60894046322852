import React, { useEffect, useRef, useState } from 'react'
import DatePicker from '../DatePicker/DatePicker'
import InputText from '../InputText/InputText'
import "./InputDate.scss"

export interface IInputDateProps {
  id: string
  name: string
  ariaLabel: string
  value: Date,
  onChange: (date: Date) => void
}

export default function InputDate ({ value, onChange, id, name, ariaLabel, }: IInputDateProps) {

  const dateValueToLocale = new Date(value.toString())
  const inputDateRef = useRef<HTMLDivElement | null>(null)
  const datePickerRef = useRef<HTMLDivElement | null>(null)
  const [showDatePicker, setShowDatePicker] = useState(false)

  const displayTime = (displayDate: Date) => {
    return displayDate.toLocaleString('sv-SE').substring(0, 10)
  }

  const onDateChange = (date: Date) => {
    onChange(date)
    setShowDatePicker(false)
  }

  const onInputFocus = () => {
    setShowDatePicker(true)
  }

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (datePickerRef.current && inputDateRef.current && !inputDateRef.current.contains(event.target as HTMLDivElement)) {
        setShowDatePicker(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [inputDateRef])


  return (
    <div className='input-date' ref={inputDateRef} onFocus={onInputFocus}>
      <div className='input-date__input' >
        <InputText name={name} id={id} placeholder="" value={displayTime(dateValueToLocale)} ariaLabel={ariaLabel} type="text" onChange={() => null}  />
      </div>
      {showDatePicker && 
      <div className='input-date__date-picker' ref={datePickerRef}>
        <>
          <div className='input-date__triangle-container'>
            <div className='input-date__triangle'></div>
          </div>
          <DatePicker onChange={onDateChange} value={value} />
        </>
      </div>
      }
    </div>
  )
}
