export const getWeekOfMonth = (date: Date) => {
  let firstWeekday = new Date(date.getFullYear(), date.getMonth(), 1).getDay() - 1
  if (firstWeekday < 0) {
    firstWeekday = 6
  }
  const offsetDate = date.getDate() + firstWeekday - 1
  return Math.floor(offsetDate / 7)
}

export const buildMonthWeeks = (date: Date) => {
  const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth()+1, 0).getDate()
  const monthDaysList = Array.from(Array(lastDayOfMonth).keys()).map(d => new Date(new Date(date).setDate(d +1)))
  const monthWeeks: Array<Array<Date | null>> = []

  monthDaysList.forEach((day) => {
    const weekNumberOfMonth = getWeekOfMonth(day)

    //add week array if new week
    if(monthWeeks.length < weekNumberOfMonth + 1) {
      monthWeeks.push([])
    }
    monthWeeks[weekNumberOfMonth].push(day)
  })

  //Add null for dates outside of month range for first week
  while(monthWeeks[0].length < 7) {
    monthWeeks[0].unshift(null)
  }

  //Add null for dates outside of month range for last week
  while(monthWeeks[monthWeeks.length -1].length < 7) {
    monthWeeks[monthWeeks.length -1].push(null)
  }

  return monthWeeks
}

export const isToday = (date: Date) => {
  const today = new Date(new Date().setHours(0,0,0,0))
  const dateToCompare = new Date(new Date(date).setHours(0,0,0,0))
  return today.getTime() === dateToCompare.getTime()
}