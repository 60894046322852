import './GroceryFeedItem.scss'
import React, { ForwardedRef, forwardRef, useRef } from 'react'
import StoreGroceryStock from '../../types/StoreGroceryStock'
import BulletMenu from '../BulletMenu/BulletMenu'
import { groceryStockStyle, groceryStockText, isStockBelowRatio } from '../../utils/groceryStockUtils'
import { dateStringToRestockDisplayDate } from '../../utils/dateUtils'

const GroceryFeedItem = forwardRef((props: { item: StoreGroceryStock, startRemoving: (param: StoreGroceryStock) => void }, ref: ForwardedRef<HTMLDivElement>) => {
  const menuRef = useRef<BulletMenu>()

  const hideMenu = () => {
    menuRef?.current?.hideMenu()
  }

  return (
    <div ref={ref} className='grocery m-2 pt-4 pr-4 pb-4 pl-5 grid grid-cols-3 md:grid-cols-4'>
      <div className='grid place-content-center col-span-1 pr-5'>
        <img
          src={`https://assets.icanet.se/image/upload/w_128,c_scale/e_sharpen:80/f_auto,q_auto:best,dpr_1.0/${props.item.ean}.webp`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null // prevents looping
            currentTarget.src='https://assets.icanet.se/image/upload/w_128,c_scale/e_sharpen:80/f_auto,q_auto:best,dpr_1.0/v1/togostoredashboard/placeholder.webp'
          }}
        />
      </div>
      <div className='grocery__details grid col-span-2 md:col-span-3'>
        <div className='grocery__details-header'>
          <div className='grocery__details-title mb-1'>
            {props.item.name}
          </div>
          <BulletMenu ref={menuRef}>
            <button className='button' onClick={() => { props.startRemoving(props.item); hideMenu() }}>Ta bort</button>
          </BulletMenu>
        </div>
        <div className='content-end'>
          <div className='grocery__details-restocked mb-1'>
            Fylldes på {dateStringToRestockDisplayDate(props.item.restockedAt)}
          </div>
          <div className='flex'>
            <div className='grocery__details-status grow'>
              {props.item.restockedQuantity - props.item.quantity} såld{props.item.restockedQuantity - props.item.quantity > 1 || props.item.restockedQuantity - props.item.quantity === 0 ? 'a' : ''}, {props.item.quantity} kvar
            </div>
            {isStockBelowRatio(props.item) &&
            <div className={`grocery__details-badge${groceryStockStyle(props.item)} content-center`}>
              <p>{groceryStockText(props.item)}</p>
            </div>}
          </div>
        </div>
      </div>
    </div>
  )
})

GroceryFeedItem.displayName = 'GroceryFeedItem'
export default GroceryFeedItem