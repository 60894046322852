import React, { Component, ErrorInfo, ReactNode } from "react"
import { logError } from '../utils/logging'

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  }

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    console.error(error)
    logError(error)
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo)
    logError(error, { componentStack: errorInfo.componentStack })
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div style={{ textAlign: 'center' }}>
          <h1>Någonting gick fel.</h1>
          <a href="/overview">Tillbaka till startsidan 	&rarr;</a>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
