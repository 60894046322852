/* eslint-disable indent */
import { useState } from "react"
import { createContainer } from "unstated-next"
import { AUTH_KEY, AUTH_TYPE_KEY } from "../constants/localStorageKeys"
import { getUserFromToken } from "../utils/getUserFromToken"
import { updateDefaultHeader } from '../utils/http'
import { getADUserFromToken } from "../utils/getADUserFromToken"
import { AuthType } from "../constants/authTypes"
//https://github.com/jamiebuilds/unstated-next

const token = localStorage.getItem(AUTH_KEY)
const authType = localStorage.getItem(AUTH_TYPE_KEY)

export interface UserResponse {
  displayName: string,
  token: string,
  roles: Array<string>,
}
export interface User extends Omit<UserResponse, 'token'> {
  token: string | null,
}

export const defaultUser = {
  displayName: "",
  token: null,
  roles: []
} as User

if (token) {
  updateDefaultHeader('Authorization', `Bearer ${token}`)
}

let initialUser = defaultUser
if (authType && token) {
  switch ((<any>AuthType)[authType]) {
    case AuthType.ActiveDirectory:
      initialUser = getADUserFromToken(token)
      break
    case AuthType.Identity:
      initialUser = getUserFromToken(token) ?? initialUser
      break
  }
}

export function useUser(initialState = initialUser) {
  const [user, setUser] = useState<User>(initialState)
  return { user, setUser }
}

export const UserContext = createContainer(useUser)
