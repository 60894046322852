import React from 'react'

export default function CloseIcon({ width = 32 }: { width?: number }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 32 32">
      <path fillRule="evenodd" d="M17.9149337,16.5010013 L23.9241463,10.4919893 C24.0252846,10.3908545 24.0252846,10.2266355 23.9241463,10.1255007 L22.8737107,9.07610147 C22.7735739,8.97496662 22.6083481,8.97496662 22.5082112,9.07610147 L16.4989986,15.0841121 L10.4917888,9.07510013 C10.3916519,8.97496662 10.2264261,8.97496662 10.1262893,9.07510013 L9.07985914,10.1214953 C8.97471544,10.2266355 8.97471544,10.3868491 9.07985914,10.4919893 L15.0850663,16.4989987 L9.07585367,22.5080107 C8.97471544,22.6091455 8.97471544,22.7733645 9.07585367,22.873498 L10.1262893,23.9238985 C10.2264261,24.0250334 10.3906506,24.0250334 10.4917888,23.9238985 L16.5,17.9148865 L22.5082112,23.9248999 C22.6083481,24.0250334 22.7735739,24.0250334 22.8737107,23.9248999 L23.9201409,22.873498 C24.0252846,22.7733645 24.0252846,22.6081442 23.9201409,22.5080107 L17.9149337,16.5010013 Z"></path>
    </svg>

  )
}
