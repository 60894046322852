import { useEffect, useState } from "react"
import { useLocation, useSearchParams } from "react-router-dom"

export interface DateRangeFromParams {
  from: Date
  to: Date
}

const useDateRangeFromParams = () => {
  const { search } = useLocation()
  const [searchParams] = useSearchParams()
  const todayFrom = new Date(new Date().setHours(0, 0, 0, 0))
  const todayTo =  new Date(new Date().setHours(23, 59, 59, 59))
  const [paramDateRange, setParamDateRange] = useState<DateRangeFromParams>({ from: todayFrom, to: todayTo })

  useEffect(() => {
    const fromDateParam = searchParams.get("fromDate")
    const toDateParam = searchParams.get("toDate")
    setParamDateRange((old) => ({
      ...old,
      from:  fromDateParam ?  new Date(new Date(fromDateParam).toString()) : todayFrom,
      to:  toDateParam ?  new Date(new Date(toDateParam).toString()) : todayTo
    }))
  }, [search])

  return { paramDateRange, setParamDateRange }
}
export default useDateRangeFromParams