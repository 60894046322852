import React, { MouseEvent, ReactNode } from 'react'
import ReactDOM from 'react-dom'
import "./Modal.scss"

export interface IModalProps {
  show: boolean
  children: Array<ReactNode>
  onClose: () => void
}

export default function Modal ({ show, children, onClose }: IModalProps) {
  const portal = document.getElementById('modal-portal')
  const onBackgroundClicked = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    const element = event.target as HTMLDivElement
    if(element.id === 'modal-root') {
      onClose()
    }
  }

  return (
    show && portal ?
      ReactDOM.createPortal(
        <div className='modal grid place-content-center' id="modal-root" onClick={onBackgroundClicked}>
          <div className='modal-body'>
            <div className='modal-header'>
              {children[0]} 
            </div>
            <div className='modal-content'>
              {children[1]} 
            </div>
          </div>
        </div>, portal
      ) : null
  )
}
