import React, { useMemo } from 'react'
import { OrderItem } from '../../types/OrderItem'
import { SessionBrief } from '../../types/SessionBrief'
import Card from '../Card/Card'
import "./TopOrderItems.scss"

export interface TopOrderItemsProps {
  sessions: Array<SessionBrief>
}

const TopOrderItems = ({ sessions } : TopOrderItemsProps) => {
  const calculateTopFiveOrdersItems = (sessions:  Array<SessionBrief>) => {
    const orderItems = sessions.flatMap((session) => {
      if(session.completed && !session.suspicious) {
        return session.orderItems
      }
      return null
    }).filter((orderItem) => Boolean(orderItem))  as Array<OrderItem>

    const orderItemsWithCount = orderItems.map((orderItem) => {
      const count = orderItems.reduce((acc, curr) => {
        return acc + (curr.itemId === orderItem.itemId ? 1 : 0)
      }, 0)
      return { ...orderItem, count }
    })
  
    return [...new Set(orderItemsWithCount.map(o => o.itemId))]
      .map(id => {
        return orderItemsWithCount.reduce((acc, curr) => curr.itemId === id ? curr : acc)
      })
      .sort((a, b) => {
        return b.count - a.count
      })
      .slice(0, 5)
  }
  const topFiveOrderItems = useMemo(() => calculateTopFiveOrdersItems(sessions), [sessions])

  return (
    <div className='top-order-items'>
      <Card>
        <>
          <div>
            <h5 className='mt-0'>Toppsäljare</h5>
          </div>
          <div className='top-order-items__list'>
            {topFiveOrderItems.map((item, i) => 
              <div key={i} className="top-order-items__list-item p-3">
                <div className='grid place-content-center'>
                  <img 
                    className="top-order-items__img" 
                    src={`https://assets.icanet.se/image/upload/w_64,c_scale/e_sharpen:80/f_auto,q_auto:best,dpr_1.0/${item.ean}.webp`}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null // prevents looping
                      currentTarget.src="https://assets.icanet.se/image/upload/w_64,c_scale/e_sharpen:80/f_auto,q_auto:best,dpr_1.0/v1/togostoredashboard/placeholder.webp"
                    }}
                  />
                </div>
                <div className='grid content-center overflow-hidden p-3'>
                  {item.displayName}
                </div>
                <div  className='grid content-center p-3 top-order-items__count'>
                  {item.count} st
                </div>
              </div>
            )}
          </div>
        </>
      </Card>
    </div>
  )
}

export default TopOrderItems