import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ArrowIcon from '../Icons/ArrowIcon'
import './NavigateBack.scss'

export interface NavigateBackProps {
  backUrl?: string
}

const NavigateBack = ({ backUrl }: NavigateBackProps) => {
  const navigate = useNavigate()
  const goBackOne = () => {
    navigate(-1)
  }

  return (
    <div className='icon-text'>
      {backUrl ?
        <Link to={backUrl}><ArrowIcon direction='left'/> Tillbaka</Link>
        : 
        <button onClick={goBackOne}><ArrowIcon direction='left'/> Tillbaka</button>
      } 
    </div>
  )
}

export default NavigateBack