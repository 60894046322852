import React from 'react'

export default function ArrowIcon({ direction }: { direction: 'up' | 'right' | 'down' | "left" }) {
  const rotate = (direction: 'up' | 'right' | 'down' | "left") => {
    switch (direction) {
    case 'up':
      return 'rotate(-90deg)'
    case 'right':
      return 'rotate(0)'
    case 'down':
      return 'rotate(90deg)'
    case 'left':
      return 'rotate(180deg)'
    default:
      return 'rotate(0)'
    }
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" style={{ transform: `${rotate(direction)}` }}>
      <path d="M26.646,15.2370124 L17.381,7.47401244 C17.276,7.38501244 17.118,7.39801244 17.029,7.50401244 L16.06,8.65001244 C15.97,8.75501244 15.984,8.91201244 16.089,9.00201244 L23.258,15.0020124 L5.25,15.0020124 C5.112,15.0020124 5,15.1140124 5,15.2520124 L5,16.7520124 C5,16.8900124 5.112,17.0020124 5.25,17.0020124 L23.258,17.0020124 L16.089,23.0020124 C15.984,23.0910124 15.97,23.2490124 16.06,23.3540124 L17.029,24.5000124 C17.118,24.6050124 17.276,24.6180124 17.381,24.5290124 L26.646,16.7660124 C27.119,16.3660124 27.119,15.6370124 26.646,15.2370124"></path>
    </svg>
  )
}
