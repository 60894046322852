import React from 'react'
import { OverviewStatistics } from '../components/OverviewStatistics/OverviewStatistics'
import { UserContext } from '../contexts/user-context'
import { isAdmin } from '../utils/userUtils'
import { StoreContext } from '../contexts/store-context'

const Overview = () => {
  const userContext = UserContext.useContainer()
  const storeContext = StoreContext.useContainer()

  return (
    <div className='pt-5'>
      {(isAdmin(userContext.user.roles) || storeContext.store.availableStores?.length > 0)
        ? <OverviewStatistics />
        : <div className='text-center'>Du har inte tillgång till några butiker.</div>
      }
    </div>
  )
}

export default Overview