import React, { useEffect, useState } from 'react'
import useSignalRFeed from '../../hooks/useSignalrFeed'
import "./Feed.scss"
import Card from '../Card/Card'
import SpinnerIcon from '../Icons/SpinnerIcon'
import { dateStringToDisplayDate } from '../../utils/dateUtils'
import StoreEventFeedItem from '../../types/StoreEventFeedItem'
import { WebSocketInvocation } from '../../hooks/webSocketInvocation'
import filterAndSortFeedItems from './filterAndSortFeedItems'
import config from '../../config'

export default function Feed() {
  const invocations: Array<WebSocketInvocation<StoreEventFeedItem>> = [{
    name: "UpsertSessionState",
    action: (data: StoreEventFeedItem) => {
      setFeed((prevFeedState: Array<StoreEventFeedItem>) => {
        return filterAndSortFeedItems(prevFeedState, data)
      })
    }
  }]

  const { initialFeed, loading, error } = useSignalRFeed<StoreEventFeedItem>(config.EVENT_FEED_SIGNALR_URL, `${config.EVENT_API_BASE_URL}/v1/storeevent`, invocations)

  const [feed, setFeed] = useState<Array<StoreEventFeedItem>>([])
  
  useEffect(() => {
    setFeed(initialFeed)
  }, [initialFeed])

  return (
    <Card>
      <div className="feed feed-table-body ">
        {loading && <div className='feed-spinner'><SpinnerIcon /> </div>}
        <div className="grid grid-flow-row auto-cols-rows lg:grid-flow-col lg:auto-cols-auto">
          <div className="">
            <div>
              <h5>Öppnat dörr</h5>
            </div>
            <div className="feed-table-column">
              {feed.filter((item) => item.sessionState === 'Visiting').map((item, i) =>
                <div className="grid grid-rows-2 place-content-center feed-table-card feed-table-card__visiting" key={i}>
                  <div>{item.id}</div>
                  <div>{dateStringToDisplayDate(item.latestActivity)}</div>
                </div>
              )}
            </div>
          </div>
          <div className="">
            <div> 
              <h5>Handlar</h5>
            </div>
            <div className="feed-table-column">
              {feed.filter((item) => item.sessionState === 'Shopping').map((item, i) =>
                <div className="grid grid-rows-2 place-content-center feed-table-card feed-table-card__shopping" key={i}>
                  <div>{item.id}</div>
                  <div>{dateStringToDisplayDate(item.latestActivity)}</div>
                </div>
              )}
            </div>
          </div>
          <div className="">
            <div>  
              <h5>Betalar</h5>
            </div>
            <div className="feed-table-column">
              {feed.filter((item) => item.sessionState === 'Paying').map((item, i) =>
                <div className="grid grid-rows-2 place-content-center feed-table-card feed-table-card__paying" key={i}>
                  <div>{item.id}</div>
                  <div>{dateStringToDisplayDate(item.latestActivity)}</div>
                </div>
              )}
            </div>
          </div>
          <div className="">
            <div>
              <h5>Dörr ut</h5>
            </div>
            <div className="feed-table-column">
              {feed.filter((item) => item.sessionState === 'Exiting').map((item, i) =>
                <div className="grid grid-rows-2 place-content-center feed-table-card feed-table-card__exiting" key={i}>
                  <div>{item.id}</div>
                  <div>{dateStringToDisplayDate(item.latestActivity)}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        {!loading && error && <p className='error'>Någonting gick fel. Försök igen genom att ladda om sidan.</p>}
      </div>
    </Card>
  )
}
