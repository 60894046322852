declare global {
  interface Window { NREUM: any; }
}

export const logError = (error: unknown, customAttributes?: Record<string, unknown>) => {
  console.error(error)
  if (window.NREUM) {
    window.NREUM.noticeError(error, customAttributes)
  }
}
