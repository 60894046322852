import  React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import useDateRangeFromParams from '../../hooks/useDateRangeFromParams'
import InputDateRange, { InputDateRangePayLoad } from '../InputDateRange/InputDateRange'
import InputSelect, { SelectOption } from '../InputSelect/InputSelect'

export interface FilterOption {
  displayText:string
  value: 'showAll' | 'suspicious'
}

export interface FilterPayload {
  fromDate: Date
  toDate: Date
  onlySuspicious?: boolean | null
}

export interface FilterProps {
  onFilter: (filter: FilterPayload) => void
  defaultFilterOption?: 'showAll' | 'suspicious'
}

export default function Filter ({ onFilter, defaultFilterOption }: FilterProps) {
  const location = useLocation()
  const { paramDateRange } = useDateRangeFromParams()
  const filterOptions: Array<FilterOption> = [{ displayText: 'Visa alla', value: 'showAll' }, { displayText: 'Alla Misstänkta', value: 'suspicious' }]
  const defaultFilterValue = filterOptions.find((f) => f.value === defaultFilterOption) ?? filterOptions[0]
  const [fromDate, setFromDate] = useState(paramDateRange.from)
  const [toDate, setToDate] = useState(paramDateRange.to)
  const [dropdownFilter, setDropdownFilter] = useState<FilterOption>(defaultFilterValue)

  const setFromDateTime = (date: Date) => {
    return new Date(new Date(date).setHours(0,0,0,0))
  }

  const setToDateTime = (date: Date) => {
    return new Date(new Date(date).setHours(23,59,59,59))
  }

  const onDropDownChange =  (option: SelectOption) => {
    const filterOption = option as FilterOption
    if (filterOption) {
      setDropdownFilter(filterOption)
    }
  }

  const onDateRangeChange = (dateRange: InputDateRangePayLoad) => {
    setFromDate(dateRange.from)
    setToDate(dateRange.to)
  }

  const onFilterChange = () => {
    onFilter({
      fromDate: setFromDateTime(fromDate),
      toDate: setToDateTime(toDate),
      onlySuspicious: dropdownFilter ? dropdownFilter.value === 'suspicious' : null
    })
  }

  useEffect(() => {
    if(paramDateRange.from !== fromDate && paramDateRange.to !== toDate) {
      setFromDate(() => paramDateRange.from)
      setToDate(() => paramDateRange.to)
    }
  }, [paramDateRange])

  return (
    <div className='filter grid md:grid-cols-3'>
      <InputDateRange onDateRangeChange={onDateRangeChange} defaultDateRange={{ from: fromDate, to: toDate }}/>
      {/* TODO: remove ifstatement when backend supports select filter on /customers*/}
      {location.pathname.includes('/sessions') ?
        <div className='mb-4'>
          <InputSelect options={filterOptions} onInputSelectChange={onDropDownChange} defaultFilterOption={defaultFilterValue}  />
        </div>
        : <div></div>
      }
      <div className='grid justify-items-end content-center px-3 mb-4'>
        <button className='button button-black button-large' onClick={onFilterChange}>Sök</button>
      </div>
    </div>
  )
}
