import React, { useEffect, useState } from 'react'
import config from '../../config'
import { post } from '../../utils/http'
import { SessionBrief } from '../../types/SessionBrief'
import { StoreContext } from '../../contexts/store-context'
import { Link, useParams } from 'react-router-dom'
import SpinnerIcon from '../Icons/SpinnerIcon'
import Filter, { FilterPayload } from '../SessionFilter/SessionFilter'
import { dateStringToSessionDisplayDate, differenceInMinutes } from '../../utils/dateUtils'
import displayPrice from '../../utils/displayPrice'
import Modal from '../Modal/Modal'
import SessionInfo from '../SessionInfo/SessionInfo'
import { useNavigate  } from "react-router-dom"
import CloseIcon from '../Icons/CloseIcon'
import UserIcon from '../Icons/UserIcon'
import sessionStateStyle from '../../utils/sessionStateStyle'
import SessionAccessDeniedInfo from '../SessionAccessDeniedInfo/SessionAccessDeniedInfo'
import AcrFlag from '../AcrFlag/AcrFlag'


export default function SessionsList() {
  const onlySuspiciousQueryParam = Boolean(new URLSearchParams(window.location.search).get('onlySuspicious'))
  const navigate = useNavigate()
  const { sessionId } = useParams()
  const { store } = StoreContext.useContainer()
  const filter: FilterPayload = { fromDate: new Date(new Date().setHours(0, 0, 0, 0)), toDate: new Date(new Date().setHours(23, 59, 59, 59)), onlySuspicious: onlySuspiciousQueryParam }
  const [sessions, setSessions] = useState<Array<SessionBrief>>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const fetchSessions = async (filterPayload: FilterPayload) => {
    setLoading(true)
    setError(false)
    setSessions([])
    try {
      const body = {
        from: filterPayload.fromDate.toISOString(),
        to: filterPayload.toDate.toISOString(),
        onlySuspicious: filterPayload.onlySuspicious
      }
     
      const result = await post<typeof body, Array<SessionBrief>>(`${config.STORE_API_BASE_URL}/v1/monitor/sessions/${store.selectedStore?.id}`, body)
      const sortedBydate = result.sort((a, b) => {
        return new Date(b.latestActivity).getTime() - new Date(a.latestActivity).getTime()
      })
      setLoading(false)
      setSessions(sortedBydate)
    } catch (error) {
      setLoading(false)
      setError(true)
    }
  }

  const searchSessions = (filter: FilterPayload) => {
    fetchSessions(filter)
  }

  const onModalClose = () => {
    navigate('/sessions')
  }

  useEffect(() => {
    if (store.selectedStore?.id) {
      fetchSessions(filter)
    }
  }, [store.selectedStore?.id])

  return (
    <div className='sessions'>
      <Filter onFilter={searchSessions} defaultFilterOption={onlySuspiciousQueryParam ? 'suspicious' : 'showAll'} />
      <div className="list">
        {loading &&
          <div className='grid place-content-center'>
            <SpinnerIcon />
          </div>
        }
        {!loading && error && <p className='error text-center'>Någonting gick fel. Försök igen genom att ladda om sidan.</p>}
        {sessions.map((session, i) => {
          return (
            <div className={`list-item ${sessionStateStyle(session)}`} key={i}>
              <Link to={`/sessions/${session.id}`} className='list-item__left-cell'>
                <div className='list-item__order'>
                  <div className='flex items-center'>#{session.id}{session.acr && <div className='ml-3'><AcrFlag acr={session.acr} width={18}/></div>}</div>
                  <div>{dateStringToSessionDisplayDate(session.firstActivity)} - {dateStringToSessionDisplayDate(session.latestActivity)} ({differenceInMinutes(session.firstActivity, session.latestActivity)} min)</div>
                </div>
                <div className='list-item__purchase'>{session.itemCount} varor{session.paymentInternalDisplayName ? ', ' + session.paymentInternalDisplayName : ''}</div>
                <div className='list-item__right flex'>
                  {session.accessDeniedReason && <div className='flex pb-4 md:pb-0'><SessionAccessDeniedInfo accessDeniedReason={session.accessDeniedReason } /></div>}
                  <div className='list-item__price'>{displayPrice(session.totalPrice)} kr</div>                  
                </div>
              </Link>
              {sessionId && sessionId === session.id &&
                <Modal show={!!sessionId} onClose={onModalClose}>
                  <div className='grid grid-cols-2 gap-4'>
                    <div className='justify-self-start'>
                      <Link to='/sessions' className='icon-text'><CloseIcon />Stäng</Link>
                    </div>
                    {session.customerId &&
                    <div className='justify-self-end'>
                      <Link to={`/customers/${session.customerId}/${session.acr}`} state={{ previousUrl: `${location.pathname}${location.search}` }} className='icon-text'>Kund <UserIcon /></Link>
                    </div>
                    }
                  </div>
                  <SessionInfo />
                </Modal>
              }
            </div>
          )
        })}
        {!loading && !error && sessions.length === 0 && <div className='grid place-items-center'>Inga kundvarv hittades för angiven butik och tidsintervall.</div>}
      </div>
    </div >
  )
}

