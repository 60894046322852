
import React from 'react'

export default function SpinnerIcon({ width }: { width?: number }) {
  return (
    <svg version="1.1" viewBox="0 0 32 32" style={{ width: width ? width + 'px' : '40px', fill: 'var(--color-red-ica)' }}>
      <path fillRule="evenodd" d="M16 5a2 2 0 10.001 4.001A2 2 0 0016 5m0 18a2 2 0 10.001 4.001A2 2 0 0016 23M8.222 8.222a2 2 0 102.83 2.829 2 2 0 00-2.83-2.83m12.728 12.73a2 2 0 102.828 2.827 2 2 0 00-2.828-2.828M9 16a2 2 0 10-4.001.001A2 2 0 009 16m16-2a2 2 0 10.001 4.001A2 2 0 0025 14M8.222 20.95a2 2 0 102.827 2.828 2 2 0 00-2.827-2.828m15.556-9.9a2 2 0 10-2.83-2.829 2 2 0 002.83 2.83"><animateTransform attributeName="transform" type="rotate" from="0 16 16" to="360 16 16" dur="2s" repeatCount="indefinite" /></path>
    </svg >
  )
}
