import React from 'react'
import CustomerList from '../components/CustomerList/CustomerList'

export default function Customers() {
  return (
    <div className='pt-5'>
      <CustomerList />
    </div>
  )
}
