import React, { useEffect, useRef, useState } from 'react'
import config from '../config'
import { get, post, updateDefaultHeader } from '../utils/http'
import { useNavigate } from "react-router-dom"
import { UserContext, UserResponse } from '../contexts/user-context'
import { AUTH_KEY, AUTH_TYPE_KEY, OAUTH_PATH_DATA_KEY } from '../constants/localStorageKeys'
import './Login.scss'
import Card from '../components/Card/Card'
import InputText from '../components/InputText/InputText'
import SpinnerIcon from '../components/Icons/SpinnerIcon'
import { AuthorizeResponse } from '../types/AuthorizeResponse'
import { TokenResponse } from '../types/TokenResponse'
import { IntrospectResponse } from '../types/IntrospectResponse'
import { getADUserFromToken } from '../utils/getADUserFromToken'
import { AuthType } from '../constants/authTypes'

const Login = () => {
  const userContext = UserContext.useContainer()
  const navigate = useNavigate()
  const [userName, setUserName] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const adLoginInProgress = useRef<any>(false)

  useEffect(() => {
    // If page is loaded with AD response code in url: continue logging in
    const params = new URLSearchParams(window.location.search)
    const authorizationCode = params.get('code')
    if (authorizationCode && !adLoginInProgress.current) {
      adLoginInProgress.current = true
      const loginAD = async () => {
        setLoading(true)
        const oAuthPathData = localStorage.getItem(OAUTH_PATH_DATA_KEY)
        if (!oAuthPathData) {
          alert('OAuthPathData missing')
          return
        }

        // Exchange code for Curity token
        const oAuthData = JSON.parse(oAuthPathData)
        const tokenData = { authorizationCode, redirectUri: getOAuthRedirectUri(), clientId: oAuthData.clientId }
        const tokenResponse = await post(`${config.AD_LOGIN_URL}/v2/token`, tokenData) as TokenResponse
        setLoading(false)
        adLoginInProgress.current = false

        if (!tokenResponse?.accessToken) {
          alert('Bad tokenResponse')
          return
        }

        // Introspect        
        const headers = { 'x-client-name': 'icanow-service', 'Authorization': `Bearer ${tokenResponse.accessToken}` }
        const introspectResponse = await get<IntrospectResponse>(`${config.AD_LOGIN_URL}/v2/introspect`, { headers })

        if (!introspectResponse?.jwt) {
          alert('Bad introspectResponse')
          return
        }

        localStorage.setItem(AUTH_KEY, introspectResponse.jwt)
        localStorage.setItem(AUTH_TYPE_KEY, AuthType[AuthType.ActiveDirectory])
        updateDefaultHeader('Authorization', `Bearer ${introspectResponse.jwt}`)
        userContext.setUser(getADUserFromToken(introspectResponse.jwt))
        localStorage.removeItem(OAUTH_PATH_DATA_KEY)

        navigate('/overview')
      }
      loginAD()
    }
  }, [])

  const submitForm = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    try {
      setLoading(true)
      const response = await post(`${config.LOGIN_URL}`, { userName, password, scope: 300 }) as UserResponse
      localStorage.setItem(AUTH_KEY, response.token)
      localStorage.setItem(AUTH_TYPE_KEY, AuthType[AuthType.Identity])
      updateDefaultHeader('Authorization', `Bearer ${response.token}`)
      userContext.setUser(response)

      navigate('/overview')

    } catch (error) {
      setLoading(false)
      alert('Wrong username or password')
    }
  }

  const handleOauthLogin = async function onLoginOauth(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault()
    setLoading(true)

    const response = await get<AuthorizeResponse>(`${config.AD_LOGIN_URL}/v2/authorize?redirect_uri=${getOAuthRedirectUri()}`)
    if (!response?.oAuthUri) {
      return
    }

    const oAuthPathData = JSON.stringify(response)
    localStorage.setItem(OAUTH_PATH_DATA_KEY, oAuthPathData)
    setLoading(false)
    window.location.href = response.oAuthUri
  }

  const getOAuthRedirectUri = function (): string {
    return `${window.location.protocol}//${window.location.host}/login`
  }

  return (
    <div className='login'>
      <div>
        <h1>ICA ToGo - Min obemannade butik</h1>
      </div>
      <div>
        <Card>
          <>
            <h2>Logga in</h2>
            <form onSubmit={submitForm} id="login-form">
              <InputText name="username" id="username" placeholder='Användarnamn' value={userName} ariaLabel="username-input" autoComplete='username' onChange={e => setUserName(e.target.value)} />
              <InputText name="password" id="password" placeholder='Lösenord' type="password" value={password} ariaLabel="password-input" autoComplete='current-password' onChange={e => setPassword(e.target.value)} />
              <br />
              <button type="submit" className='button button-large' data-testid='btn-identity-login'>Logga in </button>
            </form>
            <hr className='my-5'></hr>
            <button onClick={handleOauthLogin} className='button button-large'>
              Logga in med ICA AD-konto
            </button>
          </>
        </Card>
        <div className="grid place-content-center">
          {loading && <SpinnerIcon />}
        </div>
      </div>
    </div>
  )
}

export default Login