import React from 'react'
import './SessionAccessDeniedInfo.scss'
import { EventType } from '../../types/EventType'

export interface ISessionAccessDeniedInfo {
  accessDeniedReason: EventType,
}

export default function SessionAccessDeniedInfo({ accessDeniedReason } : ISessionAccessDeniedInfo) {
  const accessDeniedReasonTranslation = {
    AgeAccessDenied: 'Ålderskrav',
    DoorAccessDenied: '',
    NotStammisAccessDenied: 'Icke-stammis',
  }

  return (      
    <div className='justify-self-center self-center label text-gray-200 mr-3'>
      <div className='session-access-denied-info__title text-gray-100'>Spärrad</div>
      {accessDeniedReason &&<div className='session-access-denied-info__description'>{accessDeniedReasonTranslation[accessDeniedReason as keyof typeof accessDeniedReasonTranslation]}</div>}
    </div>
  )
}
