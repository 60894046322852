import React from 'react'
import { Link } from "react-router-dom"

const NotFound = () => {
  return (
    <div className='pt-5' style={{ textAlign: 'center' }}>
      <h1>404 - Sidan kunde inte hittas</h1>
      <Link to="/overview">Tillbaka till startsidan</Link>
    </div>
  )
}

export default NotFound