import React from 'react'
import RequireAuth from './RequireAuth'
import PageContainer from './PageContainer/PageContainer'
import { StoreContext } from '../contexts/store-context'

function AuthenticatedPage({ children }: { children: JSX.Element }) {
  return (
    <RequireAuth>
      <StoreContext.Provider>
        <PageContainer>
          {children}
        </PageContainer>
      </StoreContext.Provider>
    </RequireAuth>
  )
}

export default AuthenticatedPage