import React from 'react'
import { FlagDk, FlagNo } from '../Icons/Flags'

export interface IAcrFlagProps {
  acr: string,
  width?: number,
}

export default function AcrFlag({ acr, width } : IAcrFlagProps) {
  return (
    <>
      {acr === "MitID oidc" && <FlagDk width={width} />}
      {acr === "NorwegianBankID oidc" && <FlagNo width={width} />}
    </>
  )
}
