import React, { useEffect, useState } from "react"
import Header from "../Header/Header"
import './PageContainer.scss'
import { Store, StoreContext } from '../../contexts/store-context'
import { get } from "../../utils/http"
import config from "../../config"
import ArrowIcon from "../Icons/ArrowIcon"
import { STORE_ID_KEY } from "../../constants/localStorageKeys"
import { TOGO_CHANNEL_HEADER_VALUE } from "../../constants/channelConstants"
import { Channel } from "../../types/Channel"

const PageContainer = ({ children }: { children: JSX.Element }) => {
  const { setStore } = StoreContext.useContainer()
  const [storeError, setStoreError] = useState(false)
  
  const getStore = async () => {
    try {
      const channelId = (await get<Channel>(`${config.CHANNEL_API_BASE_URL}/v1/channels/header/${TOGO_CHANNEL_HEADER_VALUE}`)).id
      const params =  new URLSearchParams({ channelId })
      const result = await get<Array<Store>>(`${config.PRODUCTS_API_BASE_URL}/v1/store?${params}`)

      if (result) {
        const savedSelectedStoreId = localStorage.getItem(STORE_ID_KEY)
        const savedSelectedStore = result.find(s => s.id === savedSelectedStoreId)
        setStore({ selectedStore: savedSelectedStore ?? result[0], availableStores: result })
      }
    } catch (error) {
      setStoreError(true)
      throw error
    }
  }

  //The component renders twice in dev mode because of React.Strict https://reactjs.org/docs/strict-mode.html#detecting-unexpected-side-effects. In prod in only renders once. 
  useEffect(() => {
    getStore()
  }, [])

  return (
    <div className="page-container">
      <Header />
      {!storeError 
        ? 
        children 
        :
        <div className="text-center p-5">
          <p className='error'>Någonting gick fel med att hämta din butik.</p>
          <p><a className='error icon-text' href={window.location.href}>Försök igen genom att ladda om sidan <ArrowIcon direction="right"/></a></p>
        </div>
      }
    </div>
  )
}

export default PageContainer