import React from 'react'

// Source: https://github.com/hampusborgos/country-flags

export function FlagDk({ width = 32 }: { width?: number }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 370 280" width={width}>
      <rect width="370" height="280" fill="#c60c30"/>
      <rect width="40" height="280" x="120" fill="#fff"/>
      <rect width="370" height="40" y="120" fill="#fff"/>
    </svg>
  )
}

export function FlagNo({ width = 32 }: { width?: number }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 16" width={width}>
      <path fill="#ba0c2f" d="M0 0h22v16H0z"/>
      <g fill="#fff">
        <path d="M6 0h4v16H6z"/>
        <path d="M0 6h22v4H0z"/>
      </g>
      <g fill="#00205b">
        <path d="M7 0h2v16H7z"/>
        <path d="M0 7h22v2H0z"/>
      </g>
    </svg>
  )
}
