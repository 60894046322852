import { AUTH_KEY, AUTH_TYPE_KEY } from '../constants/localStorageKeys'

export const defaultHeaders = {
  "Content-type": "application/json; charset=UTF-8",
} as Record<string, string>

export function updateDefaultHeader(headerName: string, headerValue: string) {
  defaultHeaders[headerName] = headerValue
}

async function http<T>(path: string, config: RequestInit): Promise<T> {
  const fullConfig: RequestInit = { ...config, headers: { ...defaultHeaders, ...config.headers, }, }
  const response = await fetch(path, fullConfig)

  if (!response.ok) {
    if (response.status === 403 || response.status === 401) {
      localStorage.removeItem(AUTH_KEY)
      localStorage.removeItem(AUTH_TYPE_KEY)
      window.location.href = '/login'
    }
    throw response
  }

  if (!response.headers.get('content-type')?.includes('application/json')) {
    return response.text() as any
  }

  return response.json().catch((e) => {
    throw new Error(`Failed to parse json in response from ${path} with status ${response.status}, ${response.statusText}: ${e}`)
  })
}

export async function get<TResponseBody>(path: string, config?: RequestInit): Promise<TResponseBody> {
  const getConfig = { method: 'get', ...config, }
  return await http<TResponseBody>(path, getConfig)
}

export async function post<TRequestBody, TResponseBody>(path: string, body: TRequestBody, config?: RequestInit): Promise<TResponseBody> {
  const postConfig = { method: 'post', body: JSON.stringify(body), ...config, }
  return await http<TResponseBody>(path, postConfig)
}

export async function put<TRequestBody, TResponseBody>(path: string, body: TRequestBody, config?: RequestInit): Promise<TResponseBody> {
  const putConfig = { method: 'put', body: JSON.stringify(body), ...config, }
  return await http<TResponseBody>(path, putConfig)
}

export async function remove<TResponseBody>(path: string, config?: RequestInit): Promise<TResponseBody> {
  const deleteConfig = { method: 'delete', ...config }
  return await http<TResponseBody>(path, deleteConfig)
}
