import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import config from '../../config'
import { StoreContext } from '../../contexts/store-context'
import { dateStringToDisplayDate } from '../../utils/dateUtils'
import { post } from '../../utils/http'
import Filter, { FilterPayload } from '../SessionFilter/SessionFilter'
import SpinnerIcon from '../Icons/SpinnerIcon'
import UserIcon from '../Icons/UserIcon'
import { useSearchParams } from "react-router-dom"
import "./CustomerList.scss"
import CustomerBannedInfo from '../CustomerBannedInfo/CustomerBannedInfo'
import AcrFlag from '../AcrFlag/AcrFlag'

interface CustomerBrief {
  id: string
  acr: string
  latestActivity: string
  banned: boolean
  bannedAt: string
}

interface CustomerRequestBody {
  from: string
  to: string
}

export default function Customer() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [customerList, setCustomerList] = useState<Array<CustomerBrief>>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const { store } = StoreContext.useContainer()

  const searchCustomers = (filterPayload: FilterPayload) => {
    const updatedFilter = { from: filterPayload.fromDate.toISOString(), to: filterPayload.toDate.toISOString() }
    setSearchParams(`?fromDate=${updatedFilter.from}&toDate=${updatedFilter.to}`)
    fetchCustomers(updatedFilter)
  }

  const fetchCustomers = async (filter: CustomerRequestBody) => {
    setCustomerList([])
    setError(false)
    setLoading(true)
    try {
      const result = await post<CustomerRequestBody, Array<CustomerBrief>>(`${config.STORE_API_BASE_URL}/v1/monitor/customers/${store.selectedStore?.id}`, filter)
      setCustomerList(result)
    } catch (error) {
      setError(true)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (store.selectedStore?.id ) {
      const fromDateParam = searchParams.get("fromDate")
      const toDateParam = searchParams.get("toDate")
      const todayFrom = new Date(new Date().setHours(0, 0, 0, 0))
      const todayTo =  new Date(new Date().setHours(23, 59, 59, 59))
      fetchCustomers({      
        from: fromDateParam ? fromDateParam : todayFrom.toISOString(),
        to: toDateParam ? toDateParam : todayTo.toISOString()
      })
    }
  }, [store.selectedStore?.id])

  return (
    <>
      <Filter onFilter={searchCustomers} />
      <div className='list customer-info'>
        {loading &&
        <div className='grid place-content-center'>
          <SpinnerIcon />
        </div>
        }
        {customerList && customerList.map((c) =>
          <div className={`list-item customer-info__list-item${c.banned ? ' customer-info__header--banned' : ''}`} key={`${c.id}-${c.acr}`}>
            <Link to={`/customers/${c.id}/${c.acr}${location.search}`} state={{ previousUrl: `${location.pathname}${location.search}` }}>
              <div className='customer-info__list-item--grid grid'>
                <div className='customer-info__icon icon-text icon-large flex items-center p-3'><UserIcon /></div>
                <div className='customer-info__text'>
                  <div className='px-4 pt-4 pb-2 place-content-center'>
                    <div className='self-center flex items-center'>#{c.id}{c.acr && <div className='ml-3'><AcrFlag acr={c.acr} width={18}/></div>}</div>
                  </div>
                  <div className='px-4 pt-2 pb-4'>
                    <span>Senaste aktivitet </span>
                    <span className='whitespace-nowrap'>{dateStringToDisplayDate(c.latestActivity)}</span>
                  </div>
                </div>
                {c.banned && 
                <div className='customer-info__banned-info flex px-4 pb-4 md:pb-0'>
                  <CustomerBannedInfo bannedAt={c.bannedAt} />
                </div>}
              </div>
            </Link>
          </div>
        )}
        {!loading && error && <p className='error text-center'>Någonting gick fel. Försök igen genom att ladda om sidan.</p>}
        {!loading && !error && customerList.length === 0 && <div className='grid place-items-center'>Inga kunder hittades för angiven butik och tidsintervall.</div>}
      </div>
    </>
  )
}
