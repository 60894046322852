import React from 'react'
import './InputCheckbox.scss'

interface InputCheckboxProps {
  checked: boolean
  onCheckChanged: (e: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  id: string
}

const InputCheckbox = ({ checked, onCheckChanged, disabled = false, id }: InputCheckboxProps) => {
  
  return <>
    <input id={id} className='input-checkbox' type={'checkbox'} checked={checked} onChange={onCheckChanged} disabled={disabled}></input>
  </>
}

export default InputCheckbox